<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!--     Buttons       -->
            <div class="d-flex justify-content-end">
              <div class="mx-2">
                <b-button
                    class="btn  btn-success font-weight-bolder text-uppercase mr-3"
                    v-b-modal.addAgentModal><i class="fas fa-plus"></i> Add Aide
                </b-button>
              </div>
              <div class="mx-2">
                <div>
                  <download-excel
                      :data="items"
                      class="btn btn-primary"
                      :fields="json_fields"
                      :worksheet="`${this.$route.name}`"
                      :name="`${this.$route.name}`">
                    <i class="fas fa-file-excel"></i> Export Excel
                  </download-excel>
                </div>
              </div>
              <div class="mx-2">
                <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
              </div>
              <div class="mx-2">
                <b-button @click="getUser()" variant="info"><i class="fas fa-redo-alt"></i>
                </b-button>
              </div>
            </div>
            <!--     Agent modal       -->
            <b-modal
                id="addAgentModal"
                title="Add Aide"
                size="lg"
                @show="resetAgentFormModal"
                @hidden="resetAgentFormModal"
                scrollable
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleAddAgentSubmit" enctype="multipart/form-data">

                <div class="row mb-5">
                  <div class="col-6">
                    <label> First name<span class="text-danger">*</span></label>
                    <b-form-input
                        type="text"
                        :placeholder="`First name`"
                        v-model="agent_details.pharmacy_first_name"
                        :state="pharmacy_first_name_state"
                        required>
                    </b-form-input>
                  </div>
                  <div class="col-6">
                    <label>Last name<span class="text-danger">*</span></label>
                    <b-form-input
                        require type="text"
                        :placeholder="`Last name`"
                        v-model="agent_details.pharmacy_last_name"
                        :state="pharmacy_last_name_state"
                        required>
                    </b-form-input>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-6">
                    <label>Password<span class="text-danger">*</span></label>
                    <b-form-input required type="password" placeholder="Password"
                                  v-model="agent_details.agent_password" :state="password_state"></b-form-input>
                  </div>
                  <div class="col-6">
                    <b-form-group label="Profile image">
                      <b-form-file
                          id="profile_image"
                          ref="file"
                          accept=".png,.jpg,.jpeg">
                      </b-form-file>
                      <p v-if="agent_details.agent_profile_image">Name: {{
                          agent_details.agent_profile_image.name
                        }}</p>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-6">
                    <label>Telephone<span class="text-danger">*</span></label>
                    <VuePhoneNumberInput
                        @update="agent_details.primaryPhoneNumberupdatedObj = $event" size="sm"
                        default-country-code="BD" v-model="agent_details.agent_primary_phone_no"
                        :state="phone_number_state"/>
                    <div v-if="agent_primary_phone_no_required" class="text-danger">{{
                        agent_primary_phone_no_required
                      }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Secondary Telephone</label>
                    <VuePhoneNumberInput @update="agent_details.secondaryPhoneNumberupdatedObj = $event" size="sm"
                                         default-country-code="BD" v-model="agent_details.agent_secondary_phone_no"/>
                  </div>
                </div>


                <div class="row mb-5">
                  <div class="col-6">
                    <label>Sub type<span class="text-danger">*</span></label>
                    <v-select
                        :options="partent_sub_types"
                        :reduce="partent_sub_types => partent_sub_types.value"
                        v-model="agent_details.agent_sub_type"
                        label="name"
                        class="h-100"
                        name="name">
                    </v-select>
                    <p class="text-danger"> {{ district_required }} </p>
                  </div>
                  <div class="col-6">
                    <label>Institute<span class="text-danger">*</span></label>
                    <v-select
                        :options="institutes"
                        :reduce="institutes => institutes.id"
                        label="name"
                        v-model="agent_details.institute_id"
                        class="h-100">
                    </v-select>
                    <p class="text-danger"> {{ district_required }} </p>
                  </div>
                </div>


                <div class="row mb-5">
                  <div class="col-6">
                    <label>NID card No <span class="text-danger">*</span></label>
                    <b-form-input type="tel" placeholder="NID card No"
                                  v-model="agent_details.agent_NID_number" :state="nid_card_no_state"
                                  required></b-form-input>
                  </div>
                  <div class="col-6">
                    <b-form-group label="NID Image">
                      <b-form-file
                          id="NID_image"
                          ref="file"
                          :state="nid_image_state"
                          required
                          accept=".png,.jpg,.jpeg">
                      </b-form-file>
                      <p v-if="agent_details.agent_NID_image">Name: {{ agent_details.agent_NID_image.name }}</p>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col-6">
                    <label>District<span class="text-danger">*</span></label>

                    <v-select
                        :required="!agent_details.agent_district"
                        :options="districts"
                        :reduce="districts => districts.id"
                        v-model="agent_details.agent_district"
                        @input="getThana"
                        label="name"
                        class=""
                        name="name"
                        :state="districtState" aria-required="true">
                    </v-select>
                    <p class="text-danger"> {{ district_required }} </p>


                  </div>
                  <div ref="thana_div" class="col-6" v-if="agent_details.agent_district">
                    <label>Thana<span class="text-danger">*</span></label>
                    <v-select
                        :options="thanas"
                        :reduce="thanas => thanas.id"
                        v-model="agent_details.agent_thana"
                        label="name"
                        class="h-100"
                        name="name">
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Address<span class="text-danger">*</span></label>
                    <b-form-textarea
                        aria-required="true" required
                        id="textarea-small"
                        size="sm"
                        v-model="agent_details.agent_address"
                        placeholder="Agent Address"
                        :state="addressState"
                    ></b-form-textarea>
                  </div>
                </div>

              </form>
            </b-modal>
            <div class="row justify-content-between">
              <div class="col-md-1 mt-9">
                <div class="">
                  <b-form-group
                      class="mb-0">
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="col-md-3 ">
                <label for="">From</label>
                <input type="date" v-model="start_date" class="form-control">
              </div>
              <div class="col-md-3">
                <label for="">To</label>
                <input type="date" v-model="end_date" class="form-control">
              </div>
              <div class="col-md-1 mt-8">
                <button type="button" class="btn btn-info" @click="getUser">Filter</button>
              </div>
              <div class="col-md-4 mt-9">
                <b-form @submit="searchDoctor" @reset="onReset">
                  <b-form-group
                      label-for="filter-input"
                      label-cols-sm="1"
                      label-align-sm="right"
                      label-size="lg"
                      class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"/>

                      <b-input-group-append>
                        <b-button variant="primary" type="submit">search</b-button>
                      </b-input-group-append>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                      </b-input-group-append>

                    </b-input-group>

                  </b-form-group>
                </b-form>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-table
                    striped hover responsive
                    :items="items.length ? filtered : items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="0"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                    :filter-included-fields="filterOn"
                    thead-class="bg-primary text-white"
                    class="mt-3"
                    id="my-table"
                    show-empty>

                  <template slot="top-row" slot-scope="{ fields }">
                    <td  v-for="field in fields" :key="field.key">
                      <p v-if="field.key == 'actions' || field.key == 'image' || field.key=='otp' "></p>
                      <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                    </td>
                  </template>

                  <template #cell(image)="row">
                    <div class="is_online">
                      <img :src="userPhoto(row.item.image)" alt="image"
                           class="user_image" v-if="row.item.image" @click="showImage(row.item.image)"/>
                      <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
                      <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
                    </div>
                  </template>

                  <template #cell(otp)="row">
                    <div class="is_online">
                      <p v-if="row.item.otp===true"><i class="fas fa-check-circle text-success"></i></p>
                      <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                    </div>
                  </template>

                  <template #cell(confirmation)="row">
                    <div class="is_online">
                      <p v-if="row.item.confirmation===true"><i class="fas fa-check-circle text-success"></i></p>
                      <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                    </div>
                  </template>

                  <template #cell(registration_date)="row">
                    <p>{{ row.item.registration_date }}</p>
                  </template>

                  <template #cell(email)="row">
                    <span> {{ row.item.email ? row.item.email : 'n/a' }}</span>
                  </template>

                  <template #cell(name)="row">
                    <div  v-if="row.item.parent_id">
                      <user-parent-info :parent_id="row.item.parent_id" :parent_name="row.item.parent"/>
                    </div>
                    <router-link :to="`/user/details/${row.item.id}`" class="font-weight-bold"> {{
                        row.item.name
                      }}
                    </router-link>
                    <div>
                      <b-badge>{{ row.item.id }}</b-badge>
                    </div>
                  </template>

                  <template #cell(username)="row">
                    <span> {{ row.item.username }}</span>
                  </template>

                  <template #cell(actions)="row">
                    <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                                class="m-2">

                      <template #button-content>
                        <i class="fas fa-ellipsis-v"></i>
                      </template>

                      <b-dropdown-item href="#" @click="userDetails(row.item.id)"><span class="pr-4"><i
                          class="fas fa-eye"></i></span> View details
                      </b-dropdown-item>

                      <b-dropdown-item @click="assignOrkoCenter(row.item)">
                        <span class="pr-4"><i class="fas fa-university"></i></span> Assign Orko Center
                      </b-dropdown-item>

                      <router-link :to="`agent-wise/doctor-search/${row.item.id}`" class="dropdown-item"
                                   v-if="row.item.user_type === 'agent-pharmacy'">
                        <span class="pr-4"><i class="fas fa-plug"></i></span>Connect doctor
                      </router-link>
                      <b-dropdown-item href="#" @click="callHistory(row.item)" v-b-modal.modal-call>
                        <span class="pr-4"><i class="fas fa-phone-volume"></i></span>Call log
                      </b-dropdown-item>
                      <div v-if="row.item.user_type === 'doctor'">
                        <b-dropdown-item href="#">Withdrawal request</b-dropdown-item>
                        <b-dropdown-item @click="viewPoint(row.item)">
                          View scores
                        </b-dropdown-item>
                      </div>

                      <b-dropdown-item href="#" @click="sendInfo(row.item)" v-b-modal.modal-lg
                                       v-if="row.item.user_type === 'agent-pharmacy' || row.item.user_type === 'agent' || row.item.user_type === 'aide'">
                        <span class="pr-4"><i class="fas fa-lock-open"></i></span> Reset password
                      </b-dropdown-item>
                      <div>

                        <router-link :to="`agent-wise/doctor-search/${row.item.id}`" class="dropdown-item">
                          <span class="pr-4"><i class="fas fa-plug"></i></span>Connect doctor
                        </router-link>

                        <router-link
                            :to="`/agent-wise-patient-list/${row.item.id}`"
                            class="dropdown-item">
                          <span class="pr-4"><i class="fas fa-user-injured"></i></span> Show patients
                        </router-link>

                        <router-link :to="`agent-wise-doctors-list/${row.item.id}`" class="dropdown-item">
                          <span class="pr-4"><i class="fas fa-user-md"></i></span> Show doctor
                        </router-link>

                      </div>
                    </b-dropdown>

                  </template>

                </b-table>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <b-pagination
                    aria-controls="my-table"
                    v-model="currentPage"
                    :total-rows="total_user"
                    :per-page="perPage"
                    @change="handlePageChange"
                    size="lg"
                    class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  View points  -->
    <b-modal id="view-point" size="lg" title="Points"
             @show="resetModal"
             @hidden="resetModal">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div class="user-info d-flex align-items-center">
              <div>
                <img :src="userPhoto(user_image)" alt="" class="img-fluid user_image mr-10">
              </div>
              <div>
                <h1>{{ user_name }}</h1>
                <h3 class="text-muted">{{ user_phone_number }}</h3>
              </div>
            </div>
            <div class="total-point">
              <p class="mb-0 text-muted">Total point</p>
              <div class="display-1 text-primary">
                {{ total_point }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="points"
              :fields="point_fields"
              thead-class="bg-primary text-white"
              show-empty>
            <template #cell(created_at)="row">
              {{ row.item.created_at }}
            </template>

          </b-table>
        </div>
      </div>

    </b-modal>
    <!--    Reset password modal -->
    <b-modal id="modal-lg" size="lg" title="Reset Password" hide-footer>
      <b-form @submit="resetPassword" @reset="onReset" v-if="show">
        <b-form-group
            id="input-group-1"
            label="Password * "
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="form.password"
              type="password"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Action comment">
          <b-form-textarea
              class="mb-2"
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
          <span class="text-danger"><b>Note: </b>Provide a valid reason/comment here. No dummy or irrelevant content will be acceptable</span>
        </b-form-group>

        <b-form-group>
          <div>
            <p>N.B: Password should be unique.</p>
            <p>1. Do not use repeated password.</p>
            <p>2. Please set atleast 6 charaecter length password.</p>
            <p>3. Do not use same password for multiple users.</p>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <!--    Call history -->
    <b-modal id="modal-call" size="xl" :title="`Add call log ( ${user.user_name} )`" hide-footer>
      <b-form @submit.prevent="saveCallHisory" @reset="onReset" v-if="show">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="call_history.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="call_type">Call Type *</label>
              <select name="" id="call_type" class="form-control" v-model="call_history.call_type">
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Status *</label>
              <v-select
                  :options="call_category"
                  :reduce="call_category => call_category.id"
                  v-model="call_history.call_category"
                  class="h-100"
                  label="title"
                  name="title">
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="call_history.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="call_history.is_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder
            </b-form-checkbox>

            <div v-if="call_history.is_reminder ==1">
              <b-form-datepicker id="example-datepicker" v-model="call_history.reminder_date"
                                 class="mb-2"></b-form-datepicker>
            </div>

          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h4>Call history</h4>
            <b-table
                responsive
                striped hover
                :items="callHistories"
                :fields="call_history_filed"
                thead-class="bg-primary text-white">
            </b-table>
          </div>
        </div>

      </b-form>
    </b-modal>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>
    <AssignUserToInstitute :doctor_id="user_id" v-if="assign_user_to_institute_modal" @modalClosed="assign_user_to_institute_modal=false"/>

  </div>
</template>

<script>
import {AideMixin} from "../../../mixins/AideMixin";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import AssignUserToInstitute from "../../modal/AssignUserToInstitute";
export default {
  name: "Index",
  mixins: [AideMixin],
  components: {VuePhoneNumberInput, AssignUserToInstitute},
  title: 'Aide',
}
</script>

<style scoped>

</style>

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const AideMixin = {
    data() {
        let route_name = this.$route.name;
        return {
            // user: '',
            // Download options
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',

            breadcrumbs: route_name + 'users',
            districts: [],
            thanas: [],
            json_fields: {
                "name": {
                    field: "name",
                },
                "slug": {
                    field: "slug",
                },
                "Email": {
                    field: "email",
                },
                "Phone number": {
                    field: "phone_number",
                },
                "Registration date": {
                    field: "registration_date",
                },
                "Is confirmed ? ": {
                    field: 'confirmation',
                    callback: (value) => {
                        return value == true ? 'valid' : 'invalid';
                    },
                },

            },
            agent_details: {
                pharmacy_first_name: '',
                pharmacy_last_name: '',
                agent_password: '',
                agent_profile_image: null,
                agent_primary_phone_no: '',
                agent_secondary_phone_no: '',
                pharmacy_owner_fullname: '',
                pharmacist_name: '',
                agent_drag_license_number: '',
                agent_TIN_number: '',
                agent_NID_number: '',
                agent_NID_image: null,
                agent_district: '',
                agent_thana: '',
                agent_address: '',
                primaryPhoneNumberupdatedObj: null,
                secondaryPhoneNumberupdatedObj: null,
                agent_sub_type: '',
                institute_id: '',
            },
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            localActive: false,
            nameState: null,
            emailState: null,
            users: [],
            items: [],
            fields: [
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'username',
                    label: 'Username',
                    sortable: true
                },
                {
                    key: 'slug',
                    label: 'Slug',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone number',
                    sortable: true
                },
                {
                    key: 'confirmation',
                    label: 'Is confirmed?',
                    sortable: true,
                },
                {
                    key: 'otp',
                    label: 'Is otp verified?',
                    sortable: true,
                },
                {
                    key: 'registration_date',
                    label: 'Reg date',
                    sortable: true,
                },
                {
                    key: 'last_update_date',
                    label: 'Updated date',
                    sortable: true,
                },
                {
                    key: "actions"
                }
            ],
            filter: null,
            filterOn: [],
            filters: {
                id: '',
                name: '',
                username: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            },
            startDate: null,
            endDate: null,
            show: true,
            form: {
                comment: '',
                module_type: '',
                password: '',
            },
            base_url: process.env.VUE_APP_API_URL,
            user_id: '',
            callHistories: [],
            call_history_filed: [
                {
                    label: 'Sl',
                    key: 'index',
                    sortable: true,
                },
                {
                    label: 'Orko user',
                    key: 'fullname',
                    sortable: true,
                },
                {
                    label: 'User type',
                    key: 'user_type',
                    sortable: true,
                },
                {
                    label: 'Call category',
                    key: 'call_category',
                    sortable: true,
                },
                {
                    label: 'Call Type',
                    key: 'call_type',
                    sortable: true,
                },
                {
                    label: 'Call Status',
                    key: 'call_status',
                    sortable: true,
                },
                {
                    label: 'Call Response',
                    key: 'call_response',
                    sortable: true,
                },
                {
                    label: 'Reminder date',
                    key: 'reminder_date',
                    sortable: true,
                },
                {
                    label: 'Call action date',
                    key: 'call_action_date',
                    sortable: true
                },
                {
                    label: 'Remarks',
                    key: 'remarks',
                    sortable: true
                },
                {
                    label: 'Created at',
                    key: 'created_at',
                    sortable: true
                }

            ],
            call_history: {
                call_response: '',
                call_type: '',
                remarks: '',
                call_category: '',
                reminder_date: '',
                is_reminder: '',
            },
            call_category: [],
            user: {
                user_name: '',
                user_id: ''
            },
            pharmacy_first_name_state: null,
            pharmacy_last_name_state: null,
            password_state: null,
            phone_number_state: null,
            pharmacy_owner_fullname_state: null,
            districtState: null,
            addressState: null,
            drag_license_state: null,
            nid_card_no_state: null,
            nid_image_state: null,
            district_required: '',
            agent_primary_phone_no_required: '',
            points: [],
            total_point: '',
            point_fields: [
                {
                    label: 'Point type',
                    key: 'point_type_description',
                },
                {
                    label: 'No of occurrence',
                    key: 'no_of_occurrence',
                },
                {
                    label: 'Point earned',
                    key: 'point_earned',
                },

            ],
            user_name: '',
            user_phone_number: '',
            user_image: null,
            doctor_id: '',
            call_log: {},
            agent_type: 'agent_pharmacy',
            partent_sub_types: [],
            parient_id: '',
            total_user: 0,
            institutes: [],
            start_date: null,
            end_date: null,
            assign_user_to_institute_modal: false,
        }
    },
    mounted() {
        this.getUser();
    },
    created() {
        this.userPhoto();
        this.agentSubType();
        this.getDistrict();
        this.getInstitute();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Aide", route: "/aide"},
        ]);
    },
    methods: {
        getUser() {
            this.loading = true
            // axios.get(`https://orkoweb.services/api/v1/user/list?user_type=${user_type}&limit=-1&platform=backoffic`)
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=aide&offset=0&limit=${this.perPage}&platform=backoffic${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data
                        this.total_user = response.data.total_count;
                        this.loading = false;
                    }
                })
        },
        getInstitute() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.institutes = response.data.data
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=agent-pharmacy&offset=${correctValue}&limit=${this.perPage}&platform=backoffice${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        searchDoctor(event) {
            event.preventDefault()
            this.loading = true
            let form = new FormData();
            form.append('user_type', 'aide');
            form.append('limit', '100');
            form.append('offset', '0');
            form.append('search_text', this.filter);
            axios.post(`${process.env.VUE_APP_URL}/api/v1/user/filter/list?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.loading = false;
                        this.items = response.data.data;
                        this.total_doctor = response.data.total_count;
                    }
                })
        },
        getPoints() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/user/point/list?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.doctor_id}`)
                .then(res => {
                    this.total_point = res.data.data.total_point;
                    this.points = res.data.data.list;
                })
        },
        getDistrict() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=city&parent_id=18`)
                .then(response => {
                    this.districts = response.data.location;
                })
        },
        getThana(district_id) {
            this.agent_details.agent_thana = '';
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=town&parent_id=${district_id}`)
                .then(response => {
                    this.thanas = response.data.location;
                })
        },
        setPageTitle(route_name) {
            let breadTitle = {}
            if (route_name == "agent-pharmacy") {
                breadTitle = {title: "Agent users", route: "agent-users"}
            } else {
                breadTitle = {title: this.capitalizeFirstLetter(route_name) + " users", route: route_name + "-users"}
            }
            this.$store.dispatch(SET_BREADCRUMB, [
                breadTitle,
            ]);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.pharmacy_first_name_state = valid;
            this.pharmacy_last_name_state = valid;
            this.password_state = valid;
            this.phone_number_state = valid;
            this.pharmacy_owner_fullname_state = valid;
            this.districtState = valid;
            this.addressState = valid;
            this.drag_license_state = valid;
            this.nid_card_no_state = valid;
            this.nid_image_state = valid;
            this.phone_number_state = valid;
            return valid
        },
        resetAgentFormModal() {
            this.agent_details.pharmacy_first_name = '';
            this.agent_details.pharmacy_last_name = '';
            this.agent_details.agent_password = '';
            this.agent_details.agent_profile_image = null;
            this.agent_details.agent_primary_phone_no = '';
            this.agent_details.agent_secondary_phone_no = '';
            this.agent_details.pharmacy_owner_fullname = '';
            this.agent_details.pharmacist_name = '';
            this.agent_details.agent_drag_license_number = '';
            this.agent_details.agent_TIN_number = '';
            this.agent_details.agent_NID_number = '';
            this.agent_details.agent_NID_image = null;
            this.agent_details.agent_district = '';
            this.agent_details.agent_thana = '';
            this.agent_details.agent_address = '';
            this.agent_details.primaryPhoneNumberupdatedObj = null;
            this.agent_details.secondaryPhoneNumberupdatedObj = null;
            this.pharmacy_first_name_state = null;
            this.pharmacy_last_name_state = null;
            this.password_state = null;
            this.phone_number_state = null;
            this.pharmacy_owner_fullname_state = null;
            this.districtState = null;
            this.addressState = null;
            this.drag_license_state = null;
            this.nid_card_no_state = null;
            this.parient_id = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            this.handleAddAgentSubmit()
            // Trigger submit handler
        },
        handleSave(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleAddAgentSubmit()
        },
        handleAddAgentSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                if (this.agent_details.agent_district == '' && this.agent_details.agent_primary_phone_no == '') {
                    this.district_required = "District required";
                    this.agent_primary_phone_no_required = "Phone number required";
                }
                return
            }

            this.$nextTick(() => {
                let add_agent_info_url = `${process.env.VUE_APP_URL}/api/v1/partner/agent/register?api_token=${localStorage.getItem('api_token')}`;

                let agent_create_fd = new FormData();
                let profile_image = document.getElementById('profile_image').files[0]
                let NID_image = document.getElementById('NID_image').files[0]

                // validations start
                if (this.agent_details.primaryPhoneNumberupdatedObj == null) {
                    return this.$snotify.error('Phone no field is required.');
                }

                agent_create_fd.append("first_name", this.agent_details.pharmacy_first_name);
                agent_create_fd.append("last_name", this.agent_details.pharmacy_last_name);
                agent_create_fd.append("password", this.agent_details.agent_password);
                if (this.agent_details.primaryPhoneNumberupdatedObj !== null) {
                    agent_create_fd.append("phone_number", this.agent_details.primaryPhoneNumberupdatedObj.formattedNumber);
                }
                if (profile_image) {
                    agent_create_fd.append("profile_img", profile_image);
                }
                if (this.agent_details.secondaryPhoneNumberupdatedObj !== null) {
                    agent_create_fd.append("secondary_phone_no", this.agent_details.secondaryPhoneNumberupdatedObj.formattedNumber);
                }
                // agent_create_fd.append("organization_name", this.agent_details.pharmacy_owner_fullname);
                // agent_create_fd.append("pharmacist_name", this.agent_details.pharmacist_name);
                agent_create_fd.append("identity_card_no", this.agent_details.agent_NID_number);
                if (NID_image) {
                    agent_create_fd.append('identity_card', NID_image);
                }
                // agent_create_fd.append("drag_license", this.agent_details.agent_drag_license_number);
                // agent_create_fd.append("tin_no", this.agent_details.agent_TIN_number);
                // location related data
                agent_create_fd.append("city_id", this.agent_details.agent_district);
                agent_create_fd.append("town_id", this.agent_details.agent_thana);
                agent_create_fd.append("location", this.agent_details.agent_address);
                // below section is set default value
                agent_create_fd.append("blood_group", "A+");
                agent_create_fd.append("dob", "1990-01-01");
                agent_create_fd.append("country_id", 18);
                agent_create_fd.append("platform", "backoffice");
                agent_create_fd.append("is_partner", "1");
                agent_create_fd.append("is_system_user", "0");
                agent_create_fd.append("system_user", localStorage.getItem('user_id'));
                agent_create_fd.append("user_type", "aide");
                agent_create_fd.append("institute_id", this.agent_details.institute_id);
                // agent_create_fd.append("gender", "male");

                if (this.parient_id) {
                    agent_create_fd.append("parent_id", this.parient_id);
                }


                agent_create_fd.append("sub_type", this.agent_details.agent_sub_type);


                axios
                    .post(add_agent_info_url, agent_create_fd)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        if (response.data.status_code == '500') {
                            return this.$router.push('/access-denied');
                        }


                        this.resetAgentFormModal();
                        this.$bvModal.hide('addAgentModal')
                        this.$snotify.success('Agent added Successfully.')
                        this.getUser();
                        // setTimeout(() => {
                        //     location.reload();
                        // }, 2000);

                    })

            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            // this.currentPage = 1
        },
        userDetails(id) {
            this.$router.push(`/user/details/${id}`);
        },
        localizeDate(date) {
            // Date picker uses ISO format (yyyy-mm-dd), which is UTC. The data
            // contains locale specific date strings (mm/dd/yyyy), which `Date`
            // parses with local time-zone offset instead of UTC. Normalize the
            // ISO date so we're comparing local times.
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },
        sendInfo(item) {
            this.user_id = item.id
        },
        callHistory(item) {
            this.call_log = item;
            this.user.user_name = item.name;
            this.user.user_id = item.id;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?user_id=${item.id}&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.callHistories = response.data.data;
                });
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/category/list?call_type=outbound&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.call_category = response.data.data
                });
        },
        saveCallHisory() {

            let form = new FormData();
            form.append('user_id', this.user.user_id);
            form.append('call_category_id', this.call_history.call_category);
            form.append('call_action_status_id', 1);
            form.append('call_type', this.call_history.call_type);
            form.append('call_response', this.call_history.call_response);
            if (this.call_history.is_reminder == 1) {
                form.append('has_reminder', this.call_history.is_reminder);
                form.append('reminder_date', this.call_history.reminder_date);
            }
            form.append('remarks', this.call_history.remarks);
            form.append('agent_username', '');

            axios.post(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs/store?api_token=${localStorage.getItem('api_token')}`, form)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    } else {
                        // this.$bvModal.hide('modal-call')
                        this.call_history = [];
                        this.callHistory(this.call_log);
                        this.$snotify.success('Call log added Successfully.');
                    }

                })
        },
        resetPassword(event) {
            event.preventDefault()
            let form = new FormData();
            form.append('password', this.form.password);
            form.append('comment', this.form.comment);
            form.append('action_type', 'general');
            form.append('module_type', 'user');
            form.append('user_id', this.user_id);
            if (!this.user_id) {
                return alert("Sorry user id not found");
            }
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/user/password/update?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error('Something worng');
                    }
                    this.resetAgentFormModal();
                    this.$bvModal.hide('modal-lg')
                    this.form = '';
                    this.$snotify.success('Password successfully reset.');
                    // this.getUser();

                })
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.form.password = '';
            this.form.comment = '';
            this.call_history = {};
            this.call_log = {},
                this.$nextTick(() => {
                    this.show = true
                });


        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },
        imageExists(image_url) {
            return image_url

        },
        dateRangeClear() {
            this.startDate = '';
            this.endDate = ''
        },
        showImage(img) {
            const h = this.$createElement
            // More complex structure
            const messageVNode = h('div', {class: ['foobar']}, [
                h('b-img', {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: 'sm',
                centered: true, size: 'lg'
            })
        },
        viewPoint(item) {

            this.$bvModal.show('view-point')

            this.user_name = item.name;
            this.user_phone_number = item.phone_number;
            this.user_image = item.image;
            this.doctor_id = item.id;
            this.getPoints();
        },
        resetModal() {
            this.doctor_id = '';
            this.parient_id = '';
        },
        agentSubType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/partner/agent/sub_type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(res => {
                    for (const property in res.data.data) {
                        this.partent_sub_types.push({
                            name: property,
                            value: res.data.data[property],
                        })

                    }


                    // this.partent_sub_types = res.data.data;

                })
        },
        addNewAssistant(item) {
            this.$bvModal.show('addAgentModal');
            this.parient_id = item.id;
            this.agent_type = 'agent';
        },
        assignOrkoCenter(item) {
            this.user_id = item.id;
            this.assign_user_to_institute_modal = true;
        }

    },
    computed: {
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.registration_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            }]
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },
        filterClear() {
            this.filter = '';
            this.getUser();
        }
    },
    watch: {
        'agent_details.agent_district': function (val) {
            this.getThana(val);
        },
        'agent_details.agent_thana': function () {
            if (this.agent_details.agent_district == '') {
                this.agent_details.agent_thana = '';
            }
        }
    },
}
